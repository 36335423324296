/* container to hold the toolbar that is the width of the screen */
.floating-toolbar-container {
    width: 100vw;
    height: fit-content;
    display: flex;
    /* justify-content:safe; */
    position: fixed;
    top: 0;
    z-index: 1000;
    mix-blend-mode: exclusion;
}

/* FloatingToolbar.css */
.floating-toolbar {
    position: fixed;         /* Stays in place while scrolling */
    top: 1vh;               /* Distance from the top */
    right: 1vw;           /*  Distance from the right */
    /* background-color: #333;  /* Toolbar background color */
    /* padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); Add a shadow for depth */
    z-index: 1000;           /* Make sure it's above other content */
    mix-blend-mode: exclusion;
    font: inter;
    font-size: clamp(0.8rem, 1vw+0.5rem, 1.2rem);
    display: flex;
    flex-direction: row;
    margin: auto;
    width: auto;
  }

@media (max-width: 600px) {
  .floating-toolbar {
    font-size: clamp(0.6rem, 1vw+0.4rem, 1rem);
  }
}

.floating-toolbar button {
    color: white;
    background: transparent;
    border: none;
    padding: 8px;
    cursor: pointer;
    vertical-align: middle;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
}

.floating-toolbar button::before {
    content: '[    ] ';
    transition: content 0.3s ease;
    margin-right: 0.5rem;
    white-space: pre;
}

.floating-toolbar button:hover::before {
    content: '[ ● ] '
}

.floating-toolbar img {
    max-height: 15px;
    max-width: 15px;
}
