/* mt fuji background image */
.mt-fuji-background {
  background-image: url('./assets/mt-fuji.jpg');
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100%;
  /* display: flex; */
  /* margin: 0; */
}

/* eiffel tower background */
.eiffel-tower-background {
  background-image: url('./assets/eiffel-tower2.jpg');
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100%;
}

/* makes the background image flush with the top; used to have a white margin at the top */
* {
  margin: 0;
  padding: 0;
  /* box-sizing: border-box; */
}

/* inter font */
@import url(https://fonts.googleapis.com/css?family=Inter);

/* name */
.Name-title {
  color: white;
  mix-blend-mode: exclusion;
  /* text-align: center; */
  font-size: 10vw;
  font-family: inter;
}

/* centering container (vertically and horizontally) */
.center-container {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  display: grid;
  place-items: center;
  height: 75vh;
  /* text-align: center; */
  /* justify-content: center; */
  margin-left: auto;
  margin-right: auto;
}

/* container for the website, light mode */
.container-light {
  height: auto;
  background-color: #CCCCCC;
  padding-bottom: 20vh;
  color: #000000;
  width: fit-content;
}

/* container for website, dark mode */
.container-dark {
  height: auto;
  background-color: #333333;
  padding-bottom: 20vh;
  color: #CCCCCC;
  width: fit-content;
}

/* ensuring all text elements inherit the color scheme */
body {
  color: inherit;
}

/* title for each section of the page */
h1 {
  font-size: 3.5rem;
  font-family: inter;
  padding: 5%;
}

/* container for the about-me */
.container-about-me {
  display: grid;
  /* display: flexbox; */
  padding: 0 0 2% 7%;
  grid-template-columns: 1fr 1fr 1fr;
  /* align-items: center; */
  /* max-width: 50%; */
  gap: 4vw;
  p {
    text-align: left;
  }
  /* background-color: orange; */
  padding-right: 10%;
}

.container-about-me-inner {
  grid-template-columns: 1fr 3fr;
  display: grid;
  align-content: space-around;
  align-items: center;
  row-gap: 3%;
  column-gap: 5%;
}

.container-about-me-tower {
  max-width: 100%;
  border-radius: 30px;
}

.container-about-me-pic {
  grid-column: 1 / 3;
  width: 100%;
  padding-top: 3vh;
  border-radius: 30px;
}

.container-experience-education {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(3, 1fr);
  gap: 7%;
  row-gap: 3%;
  margin: 0 auto auto auto;
  width: 75%;
  display: grid;
  /* margin-top: 1vw; */
  height: 75vh;
}

.container-experience-education img {
  place-self: center;
}

.container-experience-right {
  /* grid-template-rows: 1fr 1fr;
  display: grid; */
  justify-content: left;
  align-content: space-around;
}

.experience-title-light {
  font-size: 1.5em;
  color: #000000;
}

.experience-title-dark {
  font-size: 1.5em;
  color: #CCCCCC;
}

.experience-subtitle {
  font-size: 1.5em;
}

.container-projects-light {
  color: #535252;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  display: grid;
  justify-items: center;
  margin-right: 7%;
  row-gap: 10%;
}

.container-projects-dark {
  color: #8E8D94;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  display: grid;
  justify-items: center;
  margin-right: 7%;
  row-gap: 10%;
} 

.inline-block {
  display: inline;
  white-space: pre-wrap;
}

.end-image-container {
  margin-top: 15%;
  width: 100%;
  overflow: hidden;
  display: grid;
  place-items: center;
}

.end-image-container img {
  height: auto;
  width: 75%;
}

.svg-responsive {
  width: 75%;
  height: auto; /* Maintain aspect ratio */
}

.icon {
  mix-blend-mode: exclusion;
}

.dark-mode-link {
  color: #ADD8E6;
}

.dark-mode-link:visited {
  color: #CBC3E3;
}

/* .strawberry-ladybug-background {
  background-image: url("../public/strawberry-ladybug.jpg");
  filter: blur(5px);
  -webkit-filter: blur(5px);
  height: 100vh;
  background-size: cover;
  background-position: center;
} */


/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

@media (max-aspect-ratio : 1/1) {
  /* @media only screen and (max-width: 600px) { */
    h1 {
      font-size: 2rem;
      font-family: inter;
      padding: 5%;
    }
    /* .container-about-me {
      grid-template-columns: 1fr;
      padding: 0 0 0 5%;
      align-items: center;
    } */

    /* .container-about-me img {
      max-width: 50%;
      align-self: center;
    } */
  
    /* .container-about-me-inner {
      grid-template-columns: 1fr;
      gap: 2vw;
      padding-right: 5%;
      align-items: center;
    } */
  
    .container-experience-education {
      grid-template-columns: 1fr;
      gap: 5% 0 5% 5%;
    }
  
    /* .container-projects-light, .container-projects-dark {
      grid-template-columns: 1fr 1fr;
      gap: 5% 0 5% 5%;
      padding-right: 5%;
    } */
  
    /* .center-container {
      height: auto;
      padding: 10% 0;
    } */
  
    h1 {
      font-size: 2rem;
      padding: 2%;
    }
  
    /* .Name-title {
      font-size: 8vw;
      mix-blend-mode: exclusion;
      font-family: inter;
    } */
  
    .icon {
      width: 50px;
      height: 50px;
      align-self: center;
    }
  
    .container-about-me-pic {
      width: 100%;
      padding-top: 2vh;
      align-self: center;
    }
  
    .end-image-container img {
      width: 90%;
    }
  
    .container-experience-education {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(3, 1fr);
      gap: 4%;
      row-gap: 3%;
      margin: auto auto auto auto;
      width: 75%;
      display: grid;
      /* margin-top: 1vw; */
      height: 75vh;
    }
  
    .container-experience-education img {
      height: 30%;
      overflow-clip-margin: content-box;
    }
  
    .container-experience-education p {
      font-size: 1.25em;
    }
  
    .container-projects-light img {
      height: 30%;
      place-self: center;
    }
  
    .container-projects-dark img {
      height: 30%;
      place-self: center;
    }
  
    /* .container-projects-light p {
      font-size: 1.25em;
    } */
  }